import React from 'react';
import { Wrapper, HeaderImage, HeaderTitleWrapper, HeaderLink, HeaderButton, HeaderTitle, CustomSlider, Background } from './styledHeader';
import ex1 from '../../../../common/Images/HomeImages/header1.png';
import ex2 from '../../../../common/Images/HomeImages/header2.png';
import ex3 from '../../../../common/Images/HomeImages/header3.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import background3 from '../../../../common/Images/backgroundImages/PP_wektor_02.svg';

export const Header = () => {

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        arrows: false

    };

    return (
        <Wrapper>
            <Background src={background3} alt='background' />
            <HeaderTitleWrapper>
                <HeaderTitle>Zadowolenie z uprawy Paulowni w polskich warunkach jest naszym priorytetem.</HeaderTitle>
                <HeaderLink to="/O-firmie-Polskie-Paulownie">
                    <HeaderButton>Czytaj więcej</HeaderButton>
                </HeaderLink>
            </HeaderTitleWrapper>

            <CustomSlider {...settings}>
                <HeaderImage src={ex1} alt='header' />
                <HeaderImage src={ex2} alt='header' />
                <HeaderImage src={ex3} alt='header' />
            </CustomSlider>
        </Wrapper>
    );
};
