import React, { useEffect, useState } from 'react';
import { CloseButton, DropLink, LinksWrapper, Span, Wrapper, Container, SmallDropBox, Image, Paragprah } from './styledDropBox';
import message from '../Images/SVG/message-square-dots-svgrepo-com.svg';
import { motion } from 'framer-motion';
import { dropBoxAnimation } from '../../core/animations';

export const DropBox = () => {
    const [dropBox, setDropBox] = useState(true);

    const toggleDropBox = () => {
        setDropBox(!dropBox);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (!dropBox) {
                setDropBox(true);
            }
        }, 60000);

        return () => clearInterval(interval);
    }, [dropBox]);

    return (
        <Wrapper>
            {dropBox === true ? (
                <Container
                    as={motion.div}
                    initial="hidden"
                    animate={dropBox ? "visible" : "hidden"}
                    exit="hidden"
                    variants={dropBoxAnimation}
                >
                    <CloseButton onClick={toggleDropBox}>✖</CloseButton>
                    <Span>Witaj na naszej stronie! <Paragprah>Skontaktuj się z nami wybierając jeden z poniższych linków</Paragprah></Span>
                    <LinksWrapper>
                        <DropLink to='Nasza-Oferta-Paulownie' onClick={() => window.scrollTo(0, 0)}  >Nasza oferta</DropLink>
                        <DropLink to='https://www.olx.pl/oferty/uzytkownik/1jaIGZ/' target='_blank' >Zamów już teraz!</DropLink>
                    </LinksWrapper>
                </Container>
            ) : (
                <SmallDropBox onClick={toggleDropBox}>
                    <Image src={message} />
                </SmallDropBox>
            )}
        </Wrapper>
    );
};
