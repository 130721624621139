import { Image, Title, Wrapper } from './styledError';
import error from '../../common/Images/LoaderImages/error.svg';

export const Error = () => {

    return (
        <Wrapper>
            <Image src={error} />
            <Title>Ups.. Wystąpił jakiś nieoczekiwany problem z pobraniem, spróbuj ponownie później!</Title>
        </Wrapper>
    );
};

