import { Link } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
    position: fixed;
    bottom: 60px;
    right: 60px;
    z-index: 999;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        right: 0px;
        bottom: 0px;
    };
`;

export const SmallDropBox = styled.div`
    background: ${({ theme }) => theme.color.hoverSecondColor};
    border-radius: 50%;
    background: ${({ theme }) => theme.color.hoverSecondColor};
    padding: 16px;
    box-shadow: 0 0 10px ${({ theme }) => theme.color.thirdColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s;
    border: 2px double white;

    &:hover {
        opacity: 0.8;
    };

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        right: 30px;
        position: absolute;
        bottom: 30px;
    };
`;

export const Image = styled.img`
    max-width: 40px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        max-width: 30px;
    };
`;


export const Container = styled.div`
    background: ${({ theme }) => theme.color.hoverSecondColor};
    color: white;
    padding: 40px;
    display:grid;
    border: 2px double white;
    box-shadow: 0 0 10px ${({ theme }) => theme.color.thirdColor};
    border-radius: 8px;
    gap: 20px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        padding: 20px;
    };
`;

export const Span = styled.span`
    font-size: 32px;
    text-align: center;
    letter-spacing: 1px;
    border-bottom: 2px solid white;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size:18px;
    };
`;

export const Paragprah = styled.p`
    font-size: 20px;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size:14px;
    };
`;

export const LinksWrapper = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        flex-direction: column;
        gap: 20px;
    };
`;

export const DropLink = styled(Link)`
    background: white;
    color: ${({ theme }) => theme.color.fontColor};
    text-decoration: none;
    font-size: 20px;
    padding: 12px 40px;
    border-radius: 10px;
    transition: 0.3s;

    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        font-size: 16px;
        margin: 0 auto;
        padding: 10px 20px;
        min-width: 160px;
        text-align: center;
    };

    &:hover {
        opacity: 0.8;
    };
`;

export const CloseButton = styled.button`
    position: absolute;
    font-size: 18px;
    right: 20px;
    top: 20px;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
        transform: scale(1.3);
    };
    
    @media (max-width: ${({ theme }) => theme.breakPoint.secondBreakPoint}px){
        top: 10px;
        
    };
`;