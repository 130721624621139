export const theme = {
    color: {
        mainColor: "#EFEFEF;",
        secondColor: "#0F7701",
        hoverSecondColor: 'rgba(15,119,1, 0.9)',
        fontColor: "#000000",
        thirdColor: '#868686',
    },

    breakPoint: {
        firstBreakPoint: 1380,
        secondBreakPoint: 1200,
        mobileMax: 767,
    }
};