import { Add, AddLink, ContactElement, ContactWrapper, ContentWrapper, FooterLink, FooterTitle, Hidden, Image, Logo, LogoLink, PrivacyWrapper, SVGLink, SVGLinks, Socials, Wrapper } from './styledFooter';
import logo from '../Images/Logo/Logo-paulownie.png';
import phone from '../Images/FooterSVG/phone.svg';
import mail from '../Images/FooterSVG/mail.svg';
import facebook from '../Images/FooterSVG/facebook.svg';

export const Footer = () => {

    const handleClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <Wrapper >
            <ContentWrapper>
                <LogoLink to="./Drzewa-tlenowe" onClick={handleClick}>
                    <Logo src={logo} />
                </LogoLink>
                <ContactWrapper>
                    <FooterTitle>Polskie Paulownie</FooterTitle>
                    <ContactElement>Adres: ul. Kolejowa 105 43-178 Ornontowice</ContactElement>
                    <ContactElement>Telefon: +48 603 554 885</ContactElement>
                    <ContactElement><Hidden>Telefon:</Hidden> + 48 787 989 631</ContactElement>
                    <ContactElement>E-Mail: polskie.paulownie@gmail.com </ContactElement>
                </ContactWrapper>

                <Socials>
                    <FooterTitle> Skontaktuj się z nami:</FooterTitle>
                    <SVGLinks>
                        <SVGLink href='https://www.facebook.com/profile.php?id=100067548942538' target='blank'><Image src={facebook} /></SVGLink>
                        <SVGLink href="mailto: polskie.paulownie@gmail.com"><Image src={mail} /></SVGLink>
                        <SVGLink href="tel:603554885"><Image src={phone} /></SVGLink>
                    </SVGLinks>
                </Socials>
            </ContentWrapper>

            <PrivacyWrapper>
                <FooterLink to="/Polityka-prywatności"  onClick={handleClick}>Polityka Prywatności</FooterLink>
                <Add>©2024 Projekt oraz realizacja <AddLink href='https://www.facebook.com/marcin.izdebski.7528/?locale=pl_PL' target='_blank'> Marcin Izdebski</AddLink></Add>
            </PrivacyWrapper>

        </Wrapper >
    );
};
