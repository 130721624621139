import React, { useState } from "react";
import { AddButton, Button, Form, Input, Label, Legend, PointWrapper, TextEditor, Wrapper } from './styledAddPost';
import 'react-quill/dist/quill.snow.css';
import { collection, addDoc } from "firebase/firestore";
import { db, imageDb } from '../../core/firebaseConfig';
import PinVerification from './Pin/pin';
import { v4 } from "uuid";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const SubpointEditor = ({ index, subpoint, onChange }) => (
    <PointWrapper>
        <Label>
            <Legend>Podpunkt {index + 1}</Legend>
            < Input
                required
                value={subpoint.title}
                onChange={(e) => onChange(index, 'title', e.target.value)}
            />
        </Label>
        < TextEditor
            required
            value={subpoint.content}
            onChange={(content) => onChange(index, 'content', content)}
        />

    </PointWrapper>
);

export const AddPost = () => {
    const [isVerified, setIsVerified] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    // eslint-disable-next-line
    const [post, setPost] = useState('');
    const [subpoints, setSubpoints] = useState([{ title: '', content: '' }]);
    const [img, setImg] = useState(null);


    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleDescriptionChange = (content) => {
        setDescription(content);
    };

    const handleSubpointChange = (index, field, value) => {
        const newSubpoints = [...subpoints];
        newSubpoints[index][field] = value;
        setSubpoints(newSubpoints);
    };

    const handleAddSubpoint = () => {
        setSubpoints([...subpoints, { title: '', content: '' }]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            let imgUrlArray = [];

            if (img !== null) {
                const imgRef = ref(imageDb, `files/${v4()}`);
                const uploadTaskSnapshot = await uploadBytes(imgRef, img);
                const imgUrl = await getDownloadURL(uploadTaskSnapshot.ref);
                imgUrlArray.push(imgUrl);
            }

            const postData = {
                title: title,
                description: description,
                content: post,
                subpoints: subpoints,
                img: imgUrlArray
            };

            await addDoc(collection(db, "posts"), postData);

            alert('Post dodano pomyślnie!🙂');
            console.log('Post added successfully');
        } catch (error) {
            alert('Coś poszło nie tak 🥺, Spróbuj jeszcze raz!');
            console.error('There was an error adding the post:', error);
        }
    }
    if (!isVerified) {
        return <PinVerification onVerify={setIsVerified} />;
    }

    return (
        <Wrapper>
            <Form onSubmit={handleSubmit}>
                <Label>
                    <Legend>Tytuł wpisu na bloga</Legend>
                    <Input required value={title} onChange={handleTitleChange} />
                </Label>
                <Label>
                    <Legend>Krótki opis wpisu (klient będzie to widział przed wejściem na w poszczególny wpis)</Legend>
                    <TextEditor required value={description} onChange={handleDescriptionChange} />
                </Label>

                {subpoints.map((subpoint, index) => (
                    <SubpointEditor
                        key={index}
                        index={index}
                        subpoint={subpoint}
                        onChange={handleSubpointChange}
                    />
                ))}
                <AddButton type="button" onClick={handleAddSubpoint} > Dodaj kolejny podpunkt < /AddButton>

                    <input type="file" onChange={(e) => setImg(e.target.files[0])} />
                    <Button type="submit">Dodaj post!</Button>
            </Form>
        </Wrapper>
    );
};
